<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton"
    @edit-button="editButton"
  >
    <v-text-field
      v-model="input.name"
      label="Название"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-text-field
      v-model="input.keyName"
      label="Ключевое название"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-text-field
      v-model="input.description"
      label="Описание"
      :rules="[$validate.required]"
    >
    </v-text-field>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import power from "@/model/power";
export default {
  components: { SingleComponent },
  data() {
    return {
      loading: true,
      mode: "Добавить",
      input: {},
    };
  },
  async created() {
    let body = {};
    if (this.$route.params.id) {
      this.mode = "Сохранить";
      body = await this.getPower(this.$route.params.id);
    }
    let model = new power();
    this.input = model.setInput(this.mode, body);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getPower: "Power/GET_POWER",
      addPower: "Power/ADD_POWER",
      editPower: "Power/EDIT_POWER",
    }),
    async addButton() {
      this.loading = true;
      let response = await this.addPower(this.input);
      if (response.type === "success") {
        this.$router.push("/power");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: this.getError(response.errors),
        });
      }
      this.loading = false;
    },
    async editButton() {
      this.loading = true;
      let response = await this.editPower(this.input);
      if (response.type === "success") {
        this.$router.push("/power");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.message,
        });
      }
      this.loading = false;
    },
    getError(errors) {
      let error = "";
      for (let i = 0; i < errors.length; i++) {
        error += errors[i].defaultMessage + ", ";
      }
      return error;
    },
  },
};
</script>
